:root {
  --heading: #4D4D4D;
}

.projects {
  padding: 100px 0px 30px 40px;
  height: 80vh;
  min-height: 940px;
}
.projects .header{
  color: var(--heading);
  font-size: 64px;
  margin: 0;
  margin-bottom: 20px;
}

.projectsBody {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 7% 10px;
  }

  @media (max-width: 1250px) {
    .projects {
      height: auto;
    }
    .projectsBody {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 900px) {
    .projects {
      height: auto;
    }
    .projectsBody {
      grid-template-columns: 1fr;
    }
  }

.mobileLinkToHome {
  color: red;
}

/* Mobile */

@media (max-width: 600px) {
  .projects {
    padding: 60px 10px 0px 10px;
    height: auto;
  }

  .projects .header{
    font-size: 64px;
    text-align: center;
    margin-bottom: 30px;
  }

  .projectsBody {
    display: flex;
    flex-direction: column;
    margin: 0px;
  }
}