:root {
    --bg: #333333;
    --text: #F8F9FA;
    --navwidth: 300px
}

.home {
    width: 100%;
    height: 100vh;
}

.home::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../assets/home_pic.jpg") top center;
    background-attachment: fixed;
    background-size: cover; 
    background-position: var(--navwidth) top;
    filter: blur(2.5px);
    z-index: -1; 
}

.home .homeContainer {
    bottom: 40px;
    right: 100px; 
    position: absolute;
    display: inline-block;
    z-index: 1;
    box-shadow: 3px 0px 4px black;
  }

    .home .homeContainer .homeInnerContainer {
        background-color: var(--bg);
        padding: 15px;
    }
  
    .home h1 {
        font-size: 96px;
        font-weight: 700;
        color: var(--text);
        margin: 0px 0px 10px 0px;
        line-height: 80px;
        text-align: right;
    }
  
  .home h2 {
    margin: 10px 0px 0px 0px;
    color: var(--text);
    font-size: 48px;
    text-align: right;
  }
  
  @media (max-width: 1650px) {
    .home h1 {
      font-size: 80px;
      line-height: 64px;
    }
  
    .home h2 {
      font-size: 32px;
    }
  }

/* Mobile */
@media (max-width: 600px) {
  .home {
    display: none;
  }
}