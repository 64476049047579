:root {
    --navwidth: 300px;
}

.main {
    color: black;
    margin-left: var(--navwidth)
}

@media screen and (max-width: 600px) {
    body {
        overflow-x: hidden;
    }
    .main {
        margin-left: 0px;
    }
}