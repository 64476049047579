:root {
    --heading: #4D4D4D;
    --navbg: #333333;
}

.about {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 15% 25% 30% 30%;
    padding: 100px 0px 30px 40px;
    height: 80vh;
    min-height: 940px;
}

.about h1 {
    grid-row-start: 1;
    grid-row-end: 2;
    color: var(--heading);
    font-size: 64px;
    margin: 0;
}

.about h2 {
    font-size: 38px;
    font-weight: 400;
}

.about p {
    font-size: 18px;
}

@media (max-width: 1650px) {
    .about p {
        font-size: 16px;
    }
}

@media (max-width: 1300px) {
    .about p {
        font-size: 14px;
    }
}

.about .container {
    padding-left: 40px;
}

.student {
    grid-row-start: 2;
    grid-row-end: 3;
}

.software::before, .researcher::before {
    content: '';
    display: block;
    background-color: var(--heading);
    width: 100px;
    height: 4px;
    border-radius: 10%;
}

.researcher {
    grid-row-start: 3;
    grid-row-end: 4;
}

.software {
    grid-row-start: 4;
    grid-row-end: 5;
}

.aboutPic {
    grid-column-start: 1;
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 5;
}

.aboutPic img {
    object-fit: cover;
    object-position: center top;
    width: 60%;
    margin: 10% 20%;
    max-height: 80vh;
}

@media (max-width: 1650px) {
    .aboutPic {
        grid-row-start: 2;
    }
}

/* Mobile */
@media (max-width: 600px) {
    .about {
        display: flex;
        flex-direction: column;
        padding: 60px 10px 0px 10px;
        height: auto;
    }

    .about h1 {
        text-align: center;
        font-size: 64px;
        margin-bottom: 30px;
    }

    .about h2 {
        font-size: 32px;
        margin-top: 20px;
        text-align: center;
    }

    .about .container {
        padding-left: 0px;
    }

    .about p {
        font-size: 14px;
        text-align: center;
        margin-bottom: 20px;
    }

    .software::before, .researcher::before {
        content: '';
        display: block;
        background-color: var(--heading);
        width: 150px;
        height: 4px;
        border-radius: 10%;
        margin: 5px auto;
    }
    .aboutPic img {
        margin: 10% 20%;
    }
}