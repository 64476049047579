:root {
    --bg: #333333;
    --text: #F8F9FA;
    --hover: #bcbcbc;
    --navwidth: 300px
}

/* ------------------------- Side Nav ----------------------- */
.sidenav {
    height: 100%;       /* Full-height: remove this if you want "auto" height */
    width: var(--navwidth);       /* Set the width of the sidebar */
    position: fixed;    /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1;         /* Stay on top */
    top: 0;             /* Stay at the top */
    left: 0;
    background-color: var(--bg);
    overflow-x: hidden; /* Disable horizontal scroll */
    margin: 0px 0px 0px 0px;
    box-shadow: 3px 0px 4px black;
    color: var(--text);
  } 

    .sidenav h1 {
        font-size: 32px;
        margin: 0px;
    }
  
    .sidenav .container {
      position: relative;
      margin: 20px 0px 10px 0px;
      text-align: center;
    }
  
    /* Side Nav pfp */
    .sidenav .pfp {
      border-radius: 50%;
      height: 200px;
    }
  
    /* Side Nav Row Icons */
    .sidenav .container .icon {
      height: 45px;
      width: 45px;
      margin: 3px 4px;
      fill: currentColor;
    }
  
    /* Side Nav Divider */
    .sidenav hr {
      margin: 30px 30px 30px 30px;
      border-top: 1.5px solid var(--bg);
      border-radius: 5%;
    }
  
    /* Side Nav Links*/
  
    .sidenav ul {
      padding: 0px 0px 0px 10px;
      margin: 0px;
    }
  
      .sidenav ul .icon {
        height: 27px;
        width: 27px;
        fill: currentColor;
        display: inline;
        margin: auto 10px;
      }
  
    .sidenav li {
      list-style-type: none;
      margin: 10px 0px 10px 0px;
    }
      .nav-link {
        padding: 1px 8px 1px 16px;
        margin: 10px 0px 10px 0px;
        display: flex;
      }
  
      .nav-link:hover {
        cursor: pointer;
        color: var(--hover);
      }
  
      .sidenav li a {
        padding: 1px 8px 1px 16px;
        margin: 10px 0px 10px 0px;
        display: flex;
      }
  
      .sidenav li h1 {
        font-size: 22px;
      }

  
    .sidenav a {
      color: var(--text);
      text-decoration: none;
    }
    .sidenav a:hover {
      color: var(--hover);
    }

.navdivider {
  display: block;
  margin: 30px auto;
  width: 80%;
  border: 3px solid var(--text);
  border-radius: 1.5px;
}


/***********************/
/* Timeline in SideNav */
/***********************/
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0px auto;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  background-color: var(--text);
  top: 25px;
  bottom: 0;
  left: 30%;
  margin-left: -1px;
}

/* Container around content */
.timeline .container {
  position: relative;
  background-color: inherit;
  width: 50%;
  left: 30%;
  margin: 0px 0px 40px 0px;
  padding: 5px 0px;
}

/* The circles on the timeline */
.timeline .container::after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  left: -11px;
  background-color: var(--bg);
  border: solid 2px var(--text);
  top: calc(50% - 6px);
  border-radius: 50%;
  z-index: 1;
}

.timeline .current::after {
  background-color: var(--text);
}

.timeline h2 {
  margin: 0px;
  padding-left: 30px;
  font-size: 24px;
  line-height: 20px;
  text-align: left;
}

.timeline .container:hover {
  cursor: pointer;
  color: var(--hover);
}

@media (min-width: 600px) {
  .mobileTopNav {
    display: none;
  }
}

/* Mobile */
@media (max-width: 600px) {
  .sidenav {
    width: 100vw;
    min-height: 100vh;
    height: auto;
    position: relative;
  }
    .sidenav h1 {
      font-size: 3em;
      margin: 0px;
    }
    .navdivider {
      display: block;
      margin: 30px auto;
      width: 40%;
      border: 3px solid var(--text);
      border-radius: 1.5px;
    }
    .sidenav .timeline {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 25% 0 0 0;
    }
    .timeline::after {
      display: none;
    }
    .timeline .container::after {
      display: none;
    }
    .timeline .container {
      left: 0%;
      margin: 0px 0px 40px 0px;
    }
    .timeline .container h2 {
      text-align: center;
      padding-left: 0;
      font-size: 2em;
    }
    .sidenav .homelink {
      display: none;
    }

  .mobileTopNav {
    position: fixed;
    background-color: var(--bg);
    width: 100%;
    z-index: 2;
    color: var(--text);
    display: flex;
    justify-content: center;
    gap: 10px;
    box-shadow: 3px 0px 4px black;
  }

    .mobileTopNav .icon {
      height: 45px;
      width: 45px;
      margin: 3px 4px;
      fill: currentColor;
    }
}